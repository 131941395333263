
.el-table ::v-deep .table-header-style {
    background-color: #f2f3fa !important;
    font-size: 14px;
    color: #13131b;
}

.el-table ::v-deep .el-table__row .cell {
    padding-top: 12px;
    padding-bottom: 12px;
}

.paper-manage-table {
    flex: 1;
    margin: 12px 0;
    font-size: 14px;
    overflow: hidden;
    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    .operate-options {
        display: flex;
        justify-content: center;
        color: #1122d8;
        .item {
            margin-right: 20px;
            cursor: pointer;
        }
        .delete {
            color: #eb6547;
        }
    }
}
