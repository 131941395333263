
.paper-manage {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 36px 30px 30px;
    .footer-paging {
        text-align: center;
    }
}
.delete-dialog {
    margin: 50px 0;
}
